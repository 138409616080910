<script setup>
import { useAuthStore } from '~/stores/auth.store';
import { unlockBodyScroll } from '~/utilities/ui-utilities';
import { trackingMethods } from '~/plugins/analytics.client';
const auth = useAuthStore();

unlockBodyScroll();
trackingMethods.myAccountLogout();
auth.logout({ redirect: true, path: '/' });
</script>
<template><div></div></template>
